// .citation-list-table .rdt_TableBody {
//   min-height: calc(100vh - 310px);
// }
.btn-group-box + .active-permit-header {
  margin-bottom: 0;
}

.citation-list-table.view-all-permit-table .rdt_TableBody {
  min-height: calc(100vh - 310px);
  /* overflow: hidden !important; */
}

@media (max-width: 767px) {
  .citation-list-table .rdt_TableBody {
    min-height: calc(100vh - 350px);
    max-height: calc(100vh - 350px);
  }
  .rdt_Pagination > div {
    width: 200px;
  }
  .citation-list-table.view-all-permit-table {
    min-height: calc(100vh - 340px);
  }
  .citation-list-table.view-all-permit-table .rdt_TableBody {
    min-height: calc(100vh - 413px);
    max-height: calc(100vh - 413px);
  }

  body.screen-zoomin-out .lpr-report-table .rdt_TableHead + div {
    min-height: calc(100vh - 145px) !important;
  }
}

@media (max-width: 450px) {
  .export-btn {
    width: 100%;
    margin-top: 10px;
    text-align: right;
  }
  .loc-searchbox .header-search-button .form-control {
    width: 100% !important;
  }
}
