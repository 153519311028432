/* @font-face {
  font-family: 'icomoon';
  src: url('../fonts/iconmoon/icomoon.eot?38d5dv');
  src: url('../fonts/iconmoon/icomoon.eot?38d5dv#iefix')
      format('embedded-opentype'),
    url('../fonts/iconmoon/icomoon.ttf?38d5dv') format('truetype'),
    url('../fonts/iconmoon/icomoon.woff?38d5dv') format('woff'),
    url('../fonts/iconmoon/icomoon.svg?38d5dv#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
} */

@font-face {
  font-family: "icomoon";
  src: url("fonts/icomoon.eot?m1nwjo");
  src: url("fonts/icomoon.eot?m1nwjo#iefix") format("embedded-opentype"),
    url("fonts/icomoon.ttf?m1nwjo") format("truetype"),
    url("fonts/icomoon.woff?m1nwjo") format("woff"),
    url("fonts/icomoon.svg?m1nwjo#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon", sans-serif !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-tickit2:before {
  content: "\e911";
  color: #394498;
}
.icon-ticket:before {
  content: "\e905";
  font-size: 24px;
}
.icon-dispute-ticket:before {
  content: "\e907";
}
.icon-license-plate:before {
  content: "\e908";
}
.icon-pay-ticket:before {
  content: "\e909";
}
.icon-ticket-number:before {
  content: "\e910";
}
.icon-parking:before {
  content: "\e906";
}

.icon-passagepassvalidation:before {
  content: "\e912";
}
