.sidebar,
body > .navbar-collapse {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 260px;
  display: block;
  z-index: 1;
  color: #fff;
  font-weight: 200;
  /*background-color: #1e1e2d;*/
  /*background-image: none;*/
  background-size: cover;
  background-position: center center;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;

  &.collapsed {
    width: 60px;
    .sidebar-wrapper {
      width: 60px;
      overflow: hidden;
      .nav-link {
        margin: 5px 5px;
        border-radius: 0;
        p {
          opacity: 0;
          width: 0;
          height: 0;
        }
      }
    }
    .logo-img {
      img {
        max-width: 32px;
      }
    }
  }

  .sidebar-wrapper {
    position: relative;
    /*max-height: calc(100vh - 75px);*/
    /*min-height: 100%;*/
    /*overflow: auto;*/
    width: 260px;
    z-index: 4;
    padding-bottom: 100px;
    /*background-color: #1e1e2d;*/
  }

  .sidebar-background {
    position: absolute;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: block;
    top: 0;
    left: 0;
    background-size: cover;
    background-position: center center;
  }

  .logo {
    padding: 5px 15px 5px 15px;
    background: rgba(255, 255, 255, 0.8);
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    /*border-bottom: none;*/
    position: relative;
    z-index: 4;
    height: 40px;

    p {
      float: left;
      font-size: 20px;
      margin: 10px 10px;
      /*color: $white-color;*/
      color: $nav-color;
      line-height: 20px;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    .simple-text {
      text-transform: uppercase;
      padding: $padding-small-vertical $padding-zero;
      display: block;
      font-size: $font-size-large;
      color: $white-color;
      text-align: center;
      font-weight: $font-weight-normal;
      line-height: 30px;
    }
  }

  .logo-tim {
    border-radius: 50%;
    border: 1px solid #333;
    display: block;
    height: 61px;
    width: 61px;
    float: left;
    overflow: hidden;

    img {
      width: 60px;
      height: 60px;
    }
  }

  .nav {
    margin-top: 20px;
    float: none;
    display: block;

    li {
      .nav-link {
        /*color: $nav-color;*/
        color: $white-color;
        // margin: 5px 15px;
        margin: 5px;
        opacity: 0.86;
        border-radius: 4px;
        // display: block;
        display: flex;
        align-items: center;
        // padding: 10px 15px;
        padding: 10px;

        &:hover {
          /*background: $nav-bg;*/
          background: rgba(255, 255, 255, 0.13);
          opacity: 1;
          /*color: $white-color;*/
        }
        &.active {
          /*background: $nav-bg;*/
          background: rgba(255, 255, 255, 0.13);
          opacity: 1;
          /*color: $white-color;*/
        }
        &.hide_active {
          background: transparent;
        }

        p {
          margin: 0;
          line-height: 31px;
          font-size: 12px;
          /*font-weight: 500;*/
          font-weight: 600;
          text-transform: uppercase;
          display: inline-flex;
        }

        i {
          font-size: 20px;
          margin-right: 15px;
          width: 30px;
          text-align: center;
          vertical-align: middle;
          float: left;
        }
      }

      &:hover .nav-link {
        background: rgba(255, 255, 255, 0.13);
        opacity: 1;
      }

      &.active .nav-link {
        color: $white-color;
        opacity: 1;
        background: rgba(255, 255, 255, 0.23);
      }

      &.separator {
        margin: 15px 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);

        & + .nav-item {
          margin-top: 31px;
        }
      }
    }

    .caret {
      margin-top: 13px;
      position: absolute;
      right: 30px;
    }

    .active-pro {
      position: absolute;
      width: 100%;
      bottom: 10px;

      a {
        color: $white-color !important;
      }
    }

    .nav-link {
      color: $white-color;
      margin: 5px 15px;
      opacity: 0.86;
      border-radius: 4px;
      text-transform: uppercase;
      line-height: 30px;
      font-size: 12px;
      font-weight: 600;
    }
  }

  .logo {
    padding: $navbar-padding-a;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    /*background-color: #1a1a27;*/
    height: 56px;

    p {
      float: left;
      font-size: 20px;
      margin: 10px 10px;
      color: $white-color;
      line-height: 20px;
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    }

    .simple-text {
      text-transform: uppercase;
      padding: $padding-small-vertical $padding-zero;
      display: block;
      font-size: $font-size-large;
      color: $white-color;
      text-align: center;
      font-weight: $font-weight-normal;
      line-height: 30px;
    }
  }

  .logo-tim {
    border-radius: 50%;
    border: 1px solid #333;
    display: block;
    height: 61px;
    width: 61px;
    float: left;
    overflow: hidden;

    img {
      width: 60px;
      height: 60px;
    }
  }

  &:after,
  &:before {
    display: block;
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
  }

  // &:before {
  //   /*opacity: 0.33;*/
  //   /*background: #000000;*/
  // }

  &:after {
    @include icon-gradient($new-purple, $purple-color-bottom);
    z-index: 3;
    opacity: 1;
  }

  // &[data-image]:after,
  // &.has-image:after {
  //   /*opacity: 0.77;*/
  // }

  &[data-color="black"]:after {
    @include icon-gradient($default-states-color, $default-states-color);
    background: transparent !important;
  }
  &[data-color="blue"]:after {
    @include icon-gradient($new-dark-blue, $blue-color-bottom);
  }
  &[data-color="azure"]:after {
    @include icon-gradient($new-blue, $azure-color-bottom);
  }
  &[data-color="green"]:after {
    @include icon-gradient($new-green, $green-color-bottom);
  }
  &[data-color="orange"]:after {
    @include icon-gradient($new-orange, $orange-color-bottom);
  }
  &[data-color="red"]:after {
    @include icon-gradient($new-red, $red-color-bottom);
  }
  &[data-color="purple"]:after {
    @include icon-gradient($new-purple, $purple-color-bottom);
  }
}

.main-panel {
  background: rgba(203, 203, 210, 0.15);
  /*background: #e6e8f1;*/
  position: relative;
  float: right;
  width: $sidebar-width;
  min-height: 100%;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;

  &.expanded {
    width: $sidebar-width-full;
  }

  > .content {
    padding: 20px 5px 40px 5px;
    /* min-height: calc(100% - 103px); */
  }

  > .footer {
    border-top: 1px solid #e7e7e7;
  }

  .navbar {
    margin-bottom: 0;
    height: 40px;
    min-height: auto;
    /*background-color: #fff !important;*/
    /*background: #fff !important;*/
  }
}

.sidebar,
.main-panel {
  // overflow: auto;
  max-height: 100%;
  height: 100%;
  -webkit-transition-property: top, bottom;
  transition-property: top, bottom;
  -webkit-transition-duration: 0.2s, 0.2s;
  transition-duration: 0.2s, 0.2s;
  -webkit-transition-timing-function: linear, linear;
  transition-timing-function: linear, linear;
  -webkit-overflow-scrolling: touch;
}

.fixed-plugin {
  .dropdown {
    .dropdown-menu {
      @include transform-translate-3d-0(-5%);
      border-radius: 10px;
      li.adjustments-line {
        border-bottom: 1px solid #ddd;
      }
      li {
        padding: 5px 2px !important;
      }
      .button-container {
        a {
          font-size: 14px;
        }
        &.show {
          @include transform-translate-3d-0(0%);
          transform-origin: 0 0;
          left: -303px !important;
        }
      }
    }
  }
}

.fixed-plugin .dropdown .dropdown-menu {
  @include transform-translate-y-dropdown(-5%);
  top: -40px !important;
  opacity: 0;
  left: -303px !important;
  transform-origin: 0 0;
}
.fixed-plugin .dropdown.show .dropdown-menu {
  opacity: 1;
  @include transform-translate-y-dropdown(0%);
  transform-origin: 0 0;
  left: -303px !important;
}

.fixed-plugin .dropdown-menu:before,
.fixed-plugin .dropdown-menu:after {
  content: "";
  display: inline-block;
  position: absolute;
  top: 65px;
  width: 16px;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
}
.fixed-plugin .dropdown-menu:before {
  border-bottom: 16px solid rgba(0, 0, 0, 0);
  border-left: 16px solid rgba(0, 0, 0, 0.2);
  border-top: 16px solid rgba(0, 0, 0, 0);
  right: -16px;
}

.fixed-plugin .dropdown-menu:after {
  border-bottom: 16px solid rgba(0, 0, 0, 0);
  border-left: 16px solid #fff;
  border-top: 16px solid rgba(0, 0, 0, 0);
  right: -15px;
}

.modal {
  &.show {
    .modal-dialog {
      -webkit-transform: translate(0, 30%);
      -o-transform: translate(0, 30%);
      transform: translate(0, 30%);
    }
  }
  &.modal-mini {
    .modal-dialog {
      max-width: 255px;
      margin: 0 auto;
    }
  }
  .modal-content {
    .modal-header {
      border-bottom: none;
      padding-top: 24px;
      padding-right: 24px;
      padding-bottom: 0;
      padding-left: 24px;

      .modal-profile {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        text-align: center;
        line-height: 5.7;
        box-shadow: 0px 5px 20px 0px rgba(0, 0, 0, 0.3);

        i {
          font-size: 32px;
          padding-top: 24px;
        }
      }
    }

    .modal-body {
      padding-top: 24px;
      padding-right: 24px;
      padding-bottom: 16px;
      padding-left: 24px;
      line-height: 1.9;
    }

    .modal-body + .modal-footer {
      padding-top: 0;
    }

    .modal-footer {
      border-top: none;
      padding-right: 24px;
      padding-bottom: 16px;
      padding-left: 24px;
      -webkit-justify-content: space-between;
      justify-content: space-between;

      .btn {
        margin: 0;
        padding-left: 16px;
        padding-right: 16px;
        width: auto;

        &:hover,
        &focus {
          text-decoration: none;
        }
      }
    }
  }
}

.ps__rail-y {
  background-color: #eee !important;
  z-index: 9;
  width: 10px;
}
.ps__thumb-y {
  background: #999;
}

@media (min-width: 320px) and (max-width: 767px) {
  /*  .main-panel {
    .navbar {
      position: fixed;
      left: 0;
      right: 0;
      top:0;
      z-index: 2;
    }
  }*/
}
@supports (-webkit-overflow-scrolling: touch) {
  .scrollbar-container .ps__rail-y {
    display: none !important;
  }
}
