.sidebarFilter {
  width: 350px;
  position: fixed;
  top: 0;
  height: 100vh;
  background: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  -webkit-border-radius: 0 0 3px 0;
  -moz-border-radius: 0 0 3px 0;
  -o-border-radius: 0 0 3px 0;
  border-radius: 0 0 3px 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  visibility: visible;
  -webkit-transform: translate3d(100%, 0, 0);
  transform: translate3d(100%, 0, 0);
  z-index: 10;
  right: -20px;
  display: flex;
  flex-direction: column;
}

.foc-open .sidebarFilter {
  visibility: visible;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  right: 0;
  z-index: 9999;
}

.sidebarHeader,
.sidebarBody,
.sidebarFooter {
  padding: 15px;
}

.sidebarHeader {
  border-bottom: 1px solid #eee;
}

body.foc-open {
  overflow: hidden;
  position: fixed;
  width: 100%;
  height: 100%;
}

body.foc-open:after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 5;
}

body.screen-zoomin-out:after {
  content: "";
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 4;
}

.screen-zoomin-out .card {
  position: fixed;
  z-index: 6;
  top: 10px;
  left: 10px;
  width: calc(100% - 20px);
  height: calc(100vh - 60px);
}

.screen-zoomin-out .fa-expand:before {
  content: "\f066";
}

.filter-button {
  display: flex;
  justify-content: flex-end;
}

.footer-btn {
  position: relative;
  float: left;
}

@media (max-width: 767px) {
  body.foc-open .main-panel .navbar {
    // position: fixed;
    // z-index: 0;
  }
}

@media (max-width: 576px) {
  .foc-open .sidebarFilter {
    z-index: 10;
  }

  .footer {
    z-index: 3;
  }

  .foc-open .fixed-bottom {
    z-index: -1;
    position: fixed;
  }
}