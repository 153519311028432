.w-150px {
  width: 150px !important;
}
.bg-light-gray {
  background: #f2f2f2;
}
.form-control-sm {
  height: 30px !important;
  font-size: 12px !important;
}
.upfate-form .row {
  border-bottom: 1px dashed #e9e7e7;
}
.tiketupdate-icon {
  opacity: 0.5;
  position: absolute;
  left: 35px;
  top: 50%;
  transform: translateY(-50%);
}
.upfate-form .form-check .form-check-label {
  padding-left: 25px !important;
  margin-right: 5px !important;
}
.tiketupdate-icon.ticket-round-icon {
  top: 46%;
}

// Chat section style

.chatImagBox {
  display: flex;
  flex-direction: column;
  width: 120px;
  background: #fff;
  border: 1px solid #eee;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1%);
  border-radius: 6px;
  overflow: hidden;
  padding: 10px;
}
.chat-imgSrc {
  max-width: 100px;
  border: 1px solid #eee;
  min-width: 100%;
  max-height: 150px;
}
.chatBtnsrc {
  font-size: 16px !important;
  background: #e0e0e0 !important;
  margin-top: 5px;
  padding: 5px 5px !important;
}
.chatImagBox-w-img {
  display: flex;
  flex-direction: column;
  width: 100%;
  background: #fff;
  border: 1px solid #eee;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1%);
  border-radius: 6px;
  overflow: hidden;
  padding: 10px;
  font-size: 11px !important;
}

@media (max-width: 1270px) {
  .upfate-form .row {
    border-bottom: 1px dashed #e9e7e7;
    flex-direction: column;
  }
  .upfate-form .row .col-auto.col input {
    margin-left: 1rem;
  }
  .upfate-form label.pl-0.pl-md-5 {
    padding-left: 1rem !important;
  }
}

@media (max-width: 768px) {
  .upfate-form .row {
    flex-direction: column;
  }
}
