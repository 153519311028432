.fs-11px {
  font-size: 11px;
}

.fs-13px {
  font-size: 13px !important;
}

.w-30px {
  width: 30px;
}

.h-30px {
  height: 30px;
}

.btn-sm-new {
  height: 30px;
  font-size: 11px !important;
  line-height: 12px;
}

.min-w-100px {
  min-width: 100px;
}

.min-w-150px {
  min-width: 150px;
}

.btn-xs-new {
  height: 24px !important;
  font-size: 11px !important;
  line-height: 12px !important;
  padding: 0px 12px !important;
  border-radius: 0.25rem;
}

.btn.btn-xs {
  border: 1px solid #d2d2d2 !important;
  font-size: 11px;
  padding: 3px 10px;
}

.btn-xs-new-a {
  padding-top: 5px !important;
}

.justify-content-between {
  justify-content: space-between;
}

.dropdown-menu .dropdown-item:first-child:hover {
  border-radius: 10px 10px 0 0;
}

.dropdown-menu .dropdown-item:last-child:hover {
  border-radius: 0 0 10px 10px;
}

.dropdown {
  .dropdown-menu {
    .dropdown-item {
      padding: 5px 10px;
      font-size: 12px;
    }
  }
}

.dropdown-item.disabled,
.dropdown-item:disabled {
  opacity: 0.5;
}

/* ========== Table Header ========== */
.rdt_TableHeadRow {
  min-height: 35px !important;
  height: 35px;
}

.citation-list-table .rdt_TableRow,
.citation-paid-table .rdt_TableRow {
  min-height: 33px !important;
  max-height: 33px;
}

.verify-audit-table.citation-list-table .rdt_TableRow,
.verify-audit-table.citation-paid-table .rdt_TableRow {
  min-height: 38px !important;
  max-height: 38px;
}

/* Table Row */
.rdt_TableRow {
  min-height: 44px !important;
  max-height: 44px;
  font-size: 12px !important;
}

// .mb-0.card {
//   .card-body {
//     /*  padding: 10px 10px 0 10px !important; */
//   }
// }

.card .rdt_Table {
  margin-top: 0;
}

// .eaqGUG {
//   /*   border: 1px solid #e1e1e6 !important;
//   border-top: 0 !important;
//   box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
// }
.active-permit-header {
  margin-bottom: 20px;
}

.sidebarFilter .card {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.sidebarFilter .card .card-body {
  padding: 15px !important;
  flex: 1;
  overflow: auto;
}

.sidebarFilter .card .card-footer {
  border-top: 1px solid rgba(0, 0, 0, 0.125) !important;
  padding: 10px 15px !important;
}

/* .rdt_Pagination {
  min-height: 40px !important;
} */

.exportButton {
  margin-top: 0;
}

/* .search-mb-0 .active-permit-header {
  margin-bottom: 0;
} */
.citation-list-table,
.citation-paid-table {
  border: 1px solid #e1e1e6;
  // border-top: 0 !important;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.citation-list-table > div:first-child,
.citation-paid-table > div:first-child,
.activity-log-table > div:first-child {
  height: calc(100vh - 300px);
  max-height: calc(100vh - 300px);
  min-height: calc(100vh - 300px);
}

.citation-list-table.has-parent-tab > div:first-child,
.invoice-list-table > div:first-child {
  height: calc(100vh - 350px);
  max-height: calc(100vh - 350px);
  min-height: calc(100vh - 350px);
}

.citation-list-table.citation-analysis-total > div:first-child {
  height: calc(100vh - 410px);
  max-height: calc(100vh - 410px);
  min-height: calc(100vh - 410px);
}

.citation-list-table.citation-analysis-payable > div:first-child {
  height: calc(100vh - 300px);
  max-height: calc(100vh - 300px);
  min-height: calc(100vh - 300px);
}

.citation-list-table.revenue-report-table > div:first-child {
  height: calc(100vh - 350px);
  max-height: calc(100vh - 350px);
  min-height: calc(100vh - 350px);
}

/* Header Action Three Dot Dropdoan menu */
.buttons-dd .dropdown-toggle::after {
  display: none;
}

.filter-btn-bottom .filter-button {
  margin-top: 17px;
}

.filter-btn-bottom .header-search-button {
  margin: -8px 0;
}

// .citation-list-table.list-of-ravenue-table > div:first-child {
// }
.lpr-report-table.get-height.card {
  margin-bottom: 0;
}

.waitlist-table {
  height: calc(100vh - 285px);
  max-height: calc(100vh - 285px);
  min-height: calc(100vh - 285px);
}

.lpr-report-table-height {
  height: calc(100vh - 270px);
  max-height: calc(100vh - 270px);
  min-height: calc(100vh - 270px);
}

.unit-permit-table > div:first-child {
  height: calc(100vh - 190px);
  max-height: calc(100vh - 190px);
  min-height: calc(100vh - 190px);
}

.meter-list-table > div:first-child {
  height: calc(100vh - 240px);
  max-height: calc(100vh - 240px);
  min-height: calc(100vh - 240px);
}
.smart-gate-list {
  height: calc(100vh - 200px);
  max-height: calc(100vh - 200px);
  min-height: calc(100vh - 200px);
}

.citation-list-table.manage-unit-table > div:first-child {
  min-height: calc(100vh - 250px);
  max-height: calc(100vh - 250px);
}

.citation-list-table.unit-permit-packages > div:first-child{
  height: calc(100vh - 260px);
  max-height: calc(100vh - 260px);
  min-height: calc(100vh - 260px);
}
.citation-list-table.manage-permit-packages > div:first-child{
  height: calc(100vh - 260px);
  max-height: calc(100vh - 260px);
  min-height: calc(100vh - 260px);
}
.MuiTableRow-root .MuiTableCell-root {
  padding: 4px 8px;
  font-size: 14px !important;
}
